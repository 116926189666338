import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { PageContextProps, PageInfoProps, PublicationProps } from '../../types/pages'
import { SectionBase, SectionLogoList, SectionPush } from '../../types/sections'
import { IExternalResourceCard } from '../../types/resources'
import { Layout, Seo } from '../../components/commons'
import { Box } from '../../atoms'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { SECTION_MARGIN } from '../../utils/constants'
import Section from '../../components/sections'
import { ResourcesHeroWithSearch, ResourcesResult } from '../../components/resources/commons'
import { ResourceContextProvider } from '../../contexts/ResourceContext'

interface ExternalResourcesQuery {
  landing: {
    meta: PublicationProps
    pageInfo: PageInfoProps
    preHeadline: string
    headline: string
    subHeadline: string
    searchPlaceholder: string
    featuredLabel: string
    featuredExternalResources: Array<IExternalResourceCard>
    sections: Array<SectionPush | SectionLogoList>
  }
  externalResources: {
    nodes: Array<IExternalResourceCard>
  }
}

const ExternalResources: React.FC<PageProps<ExternalResourcesQuery, PageContextProps>> = ({ data, pageContext }) => {
  const { landing, externalResources } = data

  return (
    <Layout
      pageId={'id'}
      locale={pageContext.locale}
      allSlugLocales={pageContext.slugLocales}
      withoutPrefooter={landing.pageInfo.hidePrefooter}
    >
      <Seo
        slug={landing.pageInfo.slug}
        canonicalUrl={landing.pageInfo.canonicalUrl}
        locale={pageContext.locale}
        allSlug={pageContext.slugLocales}
        publication={landing.meta}
        content={landing.pageInfo.seo}
      />

      <ResourceContextProvider items={externalResources.nodes}>
        <ResourcesHeroWithSearch
          preHeadline={landing.preHeadline}
          headline={landing.headline}
          subHeadline={landing.subHeadline}
          searchPlaceholder={landing.searchPlaceholder}
        />

        <Box my={SECTION_MARGIN}>
          <Grid>
            <Row>
              <Col xs={12}>
                <ResourcesResult
                  itemsPerRow={3}
                  featuredItemsPerRow={3}
                  featuredLabel={landing.featuredLabel}
                  featured={landing.featuredExternalResources}
                />
              </Col>
            </Row>
          </Grid>
        </Box>

        {landing.sections.map((s: SectionBase) => s?.model && <Section key={s.id} {...s} />)}
      </ResourceContextProvider>
    </Layout>
  )
}

export const query = graphql`
  query ExternalResourcesQuery($locale: String!) {
    landing: datoCmsResourcesGlobal(locale: $locale) {
      meta {
        firstPublishedAt
        publishedAt
        updatedAt
      }

      pageInfo: externalResourcesPageInfo {
        ...pageInfoFragment
      }
      preHeadline: externalResourcesPreHeadline
      headline: externalResourcesHeadline
      subHeadline: externalResourcesSubHeadline
      searchPlaceholder: externalResourcesSearchPlaceholder
      featuredLabel
      featuredExternalResources {
        ...externalResourceCardFragment
      }
      sections: externalResourcesSections {
        ...sectionPushFragment
        ...sectionLogoListFragment
      }
    }
    externalResources: allDatoCmsExternalResource(locale: $locale, sort: { order: DESC, fields: meta___updatedAt }) {
      nodes {
        ...externalResourceCardFragment
      }
    }
  }
`

export default ExternalResources
